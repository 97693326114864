import React from "react";
import "../css_files/projects.css";

function Sidebar({ files, onSelectFile, selectedFile }) {
  return (
    <aside className="sidebar">
      <ul>
        {files.map((file) => (
          <li
            key={file.name}
            onClick={() => onSelectFile(file)}
            className={file.name === selectedFile.name ? "active" : ""}
          >
            {file.name}
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default Sidebar;

// import React from "react";

// function Sidebar({ files, onSelectFile }) {
//   return (
//     <div className="sidebar">
//       {files.map((file) => (
//         <button key={file} onClick={() => onSelectFile(file)}>
//           {file.replace(".md", "")}
//         </button>
//       ))}
//     </div>
//   );
// }

// export default Sidebar;

// import React from "react";

// function Sidebar({ files, setSelectedFile }) {
//   return (
//     <aside className="sidebar box">
//       <ul>
//         {files.map((file, index) => (
//           <li key={index} onClick={() => setSelectedFile(file.path)}>
//             {file.name}
//           </li>
//         ))}
//       </ul>
//     </aside>
//   );
// }

// export default Sidebar;
