import React from "react";
import "../css_files/about.css";
import "../css_files/markdown.css";
import Markdown from "../components/markdown";
import aboutMd from "../md_files/about/about.md";
import videoExample from "../pictures/IMG_3033.webm";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
function About() {
  const markdown = `- A direct URL: https://www.copycat.dev/`;
  const markdownTable = `
| S/N | Pet | Image |
|--|--|--|
| 1 | Cat |![A cat looking at you](https://i.guim.co.uk/img/media/26392d05302e02f7bf4eb143bb84c8097d09144b/446_167_3683_2210/master/3683.jpg?width=465&quality=45&auto=format&fit=max&dpr=2&s=68615bab04be2077a471009ffc236509) |
| 2 | Dog |![A dog looking at you](https://ichef.bbci.co.uk/news/976/cpsprodpb/17638/production/_124800859_gettyimages-817514614.jpg)|
`;
  return (
    <div className="about-page">
      <div>
        <video controls width="600">
          <source src={require("../pictures/IMG_3033.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls width="600">
          <source src={require("../pictures/IMG_2573.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls width="600">
          <source src={require("../pictures/IMG_3142.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls width="600">
          <source src={require("../pictures/IMG_3149.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Markdown file={aboutMd} />
      <ReactMarkdown># Heading 1</ReactMarkdown>
      <ReactMarkdown>## Heading 2</ReactMarkdown>
      <ReactMarkdown>### Heading 3</ReactMarkdown>
      <ReactMarkdown>#### Heading 4</ReactMarkdown>
      <ReactMarkdown>##### Heading 5</ReactMarkdown>
      <ReactMarkdown>###### Heading 6</ReactMarkdown>
      <ReactMarkdown>**A bold text**</ReactMarkdown>
      <ReactMarkdown>*An italic text*</ReactMarkdown>
      <ReactMarkdown>__A strong text__</ReactMarkdown>
      <ReactMarkdown>1. An ordered list text</ReactMarkdown>
      <ReactMarkdown>- An unordered list text</ReactMarkdown>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        ~~A strikethrough text~~
      </ReactMarkdown>{" "}
      <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
      <div className="table-container">
        <ReactMarkdown children={markdownTable} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  );
}

export default About;
