import React from "react";
import { NavLink } from "react-router-dom";
import "../css_files/header.css";
import headerAvatar from "../pictures/header_avatar.png";
// import headerBackground from "../pictures/header_background.png";

function Header() {
  return (
    <header className="header">
      <img src={headerAvatar} alt="avatar" className="avatar" />
      <h1>Arthur</h1>
      <nav>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Main
        </NavLink>
        <NavLink
          to="/projects"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Projects
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          About
        </NavLink>
      </nav>
    </header>
  );
}

export default Header;
