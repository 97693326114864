import React, { useState, useEffect } from "react";
import "../css_files/projects.css";
import MarkdownContent from "../components/markdownContent";
import Sidebar from "../components/sidebar"; // Ensure Sidebar is imported correctly

function ProjectsPage() {
  const markdownFiles = require.context("../md_files/projects", false, /\.md$/);
  const files = markdownFiles.keys().map((filename) => ({
    name: filename.replace("./", "").replace(".md", ""),
    path: filename,
  }));
  const [selectedFile, setSelectedFile] = useState(files[0]);
  const [content, setContent] = useState("");

  useEffect(() => {
    const loadContent = async () => {
      if (selectedFile) {
        const response = await fetch(markdownFiles(selectedFile.path));
        const text = await response.text();
        setContent(text);
      }
    };
    loadContent();
  }, [selectedFile]);

  return (
    <div className="projects-page">
      <Sidebar
        files={files}
        onSelectFile={setSelectedFile}
        selectedFile={selectedFile}
      />
      <div className="markdown-box">
        <div className="file-info">
          File: {selectedFile.name}, Lines: {content.split("\n").length}, Size:{" "}
          {content.length} bytes
        </div>
        <MarkdownContent content={content} />
      </div>
    </div>
  );
}

export default ProjectsPage;
