import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Import the plugin for GitHub-flavored markdown

function MarkdownContent({ file, content }) {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    if (content) {
      // Use content directly if provided
      setMarkdownContent(content);
    } else if (file) {
      // Fetch content if a file is provided
      fetch(file)
        .then((response) => response.text())
        .then((text) => setMarkdownContent(text));
    }
  }, [file, content]);

  return (
    <ReactMarkdown
      children={markdownContent}
      remarkPlugins={[remarkGfm]} // Enable GitHub-flavored markdown for tables and other features
    />
  );
}

export default MarkdownContent;
