import React, { useEffect, useState } from "react";
import mainPicture from "../pictures/main_left_picture.jpg";
import "../css_files/main.css";
import Markdown from "../components/markdown";
import mainMd from "../md_files/main/main.md";

// import Markdown from "markdown-to-jsx";

function MainPage() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("../md_files/main/main.md")
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="main-page">
      <img src={mainPicture} alt="Main Page Visual" className="main-image" />
      <div className="markdown-box">
        <Markdown file={mainMd} />
      </div>
    </div>
  );
}

export default MainPage;
