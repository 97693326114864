import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

function Markdown({ file }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(file)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [file]);

  //return <ReactMarkdown remarkPlugins={[[remarkGfm]]}>{content}</ReactMarkdown>;
  return (
    // <ReactMarkdown
    //   children={content}
    //   remarkPlugins={[remarkGfm]}
    //   rehypePlugins={[rehypeRaw]}
    // />
    <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
  );
}

export default Markdown;
