import React from "react";
import "../css_files/footer.css";
import footerBackground from "../pictures/footer_background.png";

function Footer() {
  const buildTime = "2024-11-11 1:05 AM"; // This can be dynamically generated as needed

  return (
    <footer
      className="footer"
      style={{ backgroundImage: `url(${footerBackground})` }}
    >
      © Arthur, Update time: {buildTime}
    </footer>
  );
}

export default Footer;

// import React from "react";

// function Footer() {
//   return (
//     <footer className="footer">
//       <p>&copy; 2024 My Website</p>
//     </footer>
//   );
// }

// export default Footer;
